import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ProductContainer from '../components/ProductContainer';
import ProductDialog from '../components/ProductDialog';
import { getCartItemInfo } from '../utils';

const MemberStorePage = ({ products, addToCart, cartItems, addSnackbarMessage, marketOpen }) => {
    const [activeProducts, setActiveProducts] = React.useState([]);
    const cartItemInfo = getCartItemInfo(cartItems);
    return (
        <>
            <div className='green-heading text-center'>
                <h2>MEMBER STORE</h2>
            </div>
            <div className='layout-container'>
                <div style={{maxWidth: 1000, margin: 'auto', padding: "24px 0"}}>
                    <div className={`grid-container add-width-2`} style={{justifyContent: 'center'}}>
                        {products.length > 0 ?
                            products.map(product => {
                                return (
                                    <div key={product.id} className='grid-item-3 add-padding-2'>
                                        <ProductContainer
                                            product={product} 
                                            addToCart={addToCart}
                                            cartItem={cartItemInfo[product.id]}
                                            setActiveProduct={newProduct => setActiveProducts([newProduct])}
                                            marketOpen={marketOpen}
                                        />
                                    </div>
                                )
                            })
                            : 
                            <p className='text-center add-padding-2'>Sorry, but there are no member store products available at this time.</p>
                        }
                    </div>
                </div>
                {typeof window !== "undefined" && <ProductDialog 
                    activeProducts={activeProducts} 
                    setActiveProducts={setActiveProducts}
                    products={[]}
                    cartItems={cartItems}
                    addToCart={addToCart}
                    addSnackbarMessage={addSnackbarMessage}
                    marketOpen={marketOpen}
                />}
            </div>
        </>
    )
}

export default ({ data: { marketStatus: { marketOpen }, allProduct: { edges: products } } }) => {
    return (
        <Layout pageTitle="Member store" addLayoutProps ogUrl="/member-store/">
            <MemberStorePage products={products.map(({ node }) => node)} marketOpen={marketOpen} />
        </Layout>
    )
}


export const query = graphql`
query MemberStoreQuery {
    allProduct(filter: {status: {eq: "ACTIVE"}, category: {eq: "Member Store"}}) {
      edges {
        node {
          id
          about
          category
          name
          path
          price
          displayPrice
          priceOption
          sortPrice
          qtyAvailable
          priceOptionItems {
            description
            name
            price
            qtyAvailable
          }
          farm {
            path
            name
          }
          productImage {
            childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
            }
          }
        }
      }
    }
    marketStatus {
        marketOpen
    }
  }
`